import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import LazyHydrate from 'react-lazy-hydration'
import { ExternalLink } from '@/atoms/ExternalLink'
import { FacebookCircleSimpleIcon } from '@/atoms/Icons/FacebookCircleSimpleIcon'
import { InstagramCircleIcon } from '@/atoms/Icons/InstagramCircleIcon'
import { TikTokCircleIcon } from '@/atoms/Icons/TikTokCircleIcon'
import { TwitterXCircleIcon } from '@/atoms/Icons/TwitterXCircleIcon'
import { YouTubeCircleIcon } from '@/atoms/Icons/YouTubeCircleIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { paths } from '@/constants'
import { soundOfFreedomTomatoUpdatedDateTime } from '@/constants/rottenTomatoes'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { useThemeContext } from '@/contexts/ThemeContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { ManageCookieConsentLink, ManageDoNotSellLink } from '@/molecules/CookieManagement'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { ExperimentVariantPicker } from '@/organisms/Footer/ExperimentVariantPicker'
import { getYouTubeLocaleLink, useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { getCurrentYear } from '@/utils/date-utils'
import { Translate, TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { TicketsFooterSweepstakesRules } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsFooterSweepstakesRules'

const watch = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('watch', 'Watch'),
  links: [
    { label: t('theWingfeatherSaga', 'The Wingfeather Saga'), internalHref: paths.watch.wingfeatherSaga },
    { label: t('tuttleTwins', 'Tuttle Twins'), internalHref: paths.watch.tuttleTwins },
    { label: t('dryBarComedy', 'Dry Bar Comedy'), internalHref: paths.watch.dryBar },
    { label: t('hisOnlySon', 'His Only Son'), internalHref: paths.watch.hisOnlySon },
    { label: t('viewAll', 'View All'), internalHref: paths.watch.index },
  ],
})

const genres = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('genres', 'Genres'),
  links: [
    {
      label: t('christian', 'Christian'),
      internalHref: paths.category.christianMovies,
    },
    {
      label: t('comedy', 'Comedy'),
      internalHref: paths.category.comedyTvShows,
    },
    {
      label: t('drama', 'Drama'),
      internalHref: paths.category.drama,
    },
    {
      label: t('documentary', 'Documentary'),
      internalHref: paths.category.documentaries,
    },
    {
      label: t('free', 'Free'),
      internalHref: paths.category.free,
    },
    {
      label: t('kidsShows', 'Kids Shows'),
      internalHref: paths.category.kidsShows,
    },
  ],
})

const upcoming = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('investAngelProjects', 'Invest in Angel Projects'),
  links: [
    {
      label: 'DAVID',
      externalHref: paths.external.invest.david,
      projectSlug: 'david',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'Live Not By Lies',
      externalHref: paths.external.invest.liveNotByLies,
      projectSlug: 'live',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'Gabriel and the Guardians',
      externalHref: paths.external.invest.gabrielAndTheGuardians,
      projectSlug: 'guardians',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'The Axiom Chronicles',
      externalHref: paths.external.invest.axiom,
      projectSlug: 'axiom',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: t('viewAll', 'View All'),
      externalHref: paths.external.invest.index,
      linkContext: 'footer',
      investLink: true,
    },
  ],
})

const about = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('about', 'About'),
  links: [
    { label: t('company', 'Company'), internalHref: paths.about.index },
    { label: t('ourMission', 'Our Mission'), internalHref: paths.about.ourMission },
    { label: t('careers', 'Careers'), externalHref: paths.external.careers },
    { label: t('press', 'Press'), internalHref: paths.press.index },
    { label: t('blog', 'Blog'), internalHref: paths.blog.index },
    { label: t('guild', 'Guild'), internalHref: paths.guild.join },
    { label: t('payItForward', 'Pay it Forward'), internalHref: paths.payItForward.index },
    {
      label: t('investorRelations', 'Investor Relations'),
      externalHref: paths.external.investorRelations.index,
    },
    { label: t('help', 'Help'), externalHref: paths.external.zendesk.support },
  ],
})

const legal = (
  t: TranslateFunction,
  slug: string,
  projectName: string,
): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('legal', 'Legal'),
  links: [
    { label: t('privacyPolicy', 'Privacy Policy'), internalHref: paths.legal.privacyPolicy },
    { label: t('termsOfUse', 'Terms of Use'), internalHref: paths.legal.termsOfUse },
    { label: t('supportedDevices', 'Supported Devices'), internalHref: paths.legal.devices },
    { label: t('manageCookiePreferences', 'Manage Cookie Preferences'), manageCookieLink: true },
    { label: t('doNotSellMyInformation', 'Do Not Sell My Information'), doNotSellLink: true },
    {
      label: t('freeTicketTermsProjectNameSlug', 'Free Ticket Terms - {{ projectName }}', {
        projectName,
      }),
      internalHref: `/legal/${slug}/free-ticket-terms`,
    },
    { label: t('help', 'Help'), externalHref: paths.external.zendesk.support },
  ],
})

const shows = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('learnMore', 'Learn More'),
  links: [
    {
      label: 'SIGHT',
      internalHref: paths.movies.sight,
    },
    {
      label: 'Sound of Hope',
      internalHref: paths.movies.soundOfHopeTheStoryOfPossumTrot,
    },
    {
      label: 'Bonhoeffer',
      internalHref: paths.movies.bonhoeffer,
    },
    {
      label: 'Homestead',
      internalHref: paths.watch.homesteadSeries,
    },
    {
      label: 'Brave the Dark',
      internalHref: paths.movies.braveTheDark,
    },
    {
      label: 'Rule Breakers',
      internalHref: paths.movies.ruleBreakers,
    },
    {
      label: 'The Riot and the Dance',
      internalHref: paths.shows.riotAndTheDance,
    },
  ],
})

const footerSectionSpacingClasses = 'py-6 sm:mr-10 lg:mr-6 lg:basis-[50%] xl:basis-[28%]'

interface FooterProps {
  slug: string
  projectName: string
  className?: string
}
export const TicketsMainFooter: React.FC<FooterProps> = ({ slug, projectName, className }) => {
  const { pathname } = useRouter()
  const { locale } = useLocale()
  // Requested to have dark footer on every page
  const isDarkMode = true
  const footerContentTextClasses = `font-light text-[14px] xl:max-w-[150px] break-words ${
    isDarkMode ? 'text-gray-300' : 'text-gray-700'
  }`
  const { t } = useTranslate('common')

  const updatedDate = Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(
    new Date(soundOfFreedomTomatoUpdatedDateTime),
  )
  const currentYear = getCurrentYear()
  const tomatoUpdate = t('rottenTomatoesScoreUpdatedDate', `Rotten Tomatoes score last updated {{updatedDate}}`, {
    updatedDate,
  })

  return (
    <>
      <footer
        className={classNames(
          'relative z-10',
          className,
          isDarkMode ? 'bg-core-gray-950 text-white' : 'bg-white text-black',
        )}
      >
        <PaddedContainer className="max-w-[1600px] pb-16">
          <div className="flex w-full flex-col items-stretch pt-8 md:px-14 lg:px-0 xl:flex-row">
            <div className="ml-4 flex flex-col lg:ml-0 xl:flex-row xl:flex-wrap">
              <FooterSection {...watch(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...genres(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...upcoming(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...legal(t, slug, projectName)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...about(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...shows(t)} footerContentTextClasses={footerContentTextClasses} />
            </div>

            <LazyHydrate whenVisible>
              <div
                className={classNames(
                  'text-xs xl:text-[12px] 2xl:text-xs ml-4 lg:ml-2',
                  footerSectionSpacingClasses,
                  footerContentTextClasses,
                  '!basis-full flex-grow !mr-0 md:!max-w-[280px] lg:!max-w-[260px] xl:!max-w-[260px] 2xl:!max-w-[330px]',
                )}
              >
                <ExperimentVariantPicker key="experiment-variant-picker" logoColor={isDarkMode ? 'white' : 'black'} />

                <ul className="mt-6 flex flex-row space-x-6">
                  <li>
                    <ExternalLink
                      aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'Facebook' })}
                      href="https://www.facebook.com/AngelStudios.Inc"
                      className="transition-all duration-200 ease-in-out hover:brightness-125"
                    >
                      <FacebookCircleSimpleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'YouTube' })}
                      href={getYouTubeLocaleLink(locale)}
                      className="transition-all duration-200 ease-in-out hover:brightness-125"
                    >
                      <YouTubeCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'X' })}
                      href="https://twitter.com/AngelStudiosInc"
                      className="transition-all duration-200 ease-in-out hover:brightness-125"
                    >
                      <TwitterXCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'Instagram' })}
                      href="https://www.instagram.com/angelstudios_inc/"
                      className="transition-all duration-200 ease-in-out hover:brightness-125"
                    >
                      <InstagramCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'TikTok' })}
                      href="https://www.tiktok.com/@angelstudiosinc"
                      className="transition-all duration-200 ease-in-out hover:brightness-125"
                    >
                      <TikTokCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                    </ExternalLink>
                  </li>
                </ul>
                <p className="mt-4 text-xs xl:text-[12px] 2xl:text-xs">
                  <Translate t={t} i18nKey="angelStudiosIsANewKindOfStudiov2">
                    Angel Studios is a new kind of movie studio - we produce and distribute award-winning titles from
                    independent creators.
                  </Translate>
                </p>
                {pathname?.includes('sound-of-freedom') && (
                  <>
                    <p className="mt-4 text-xs xl:text-[12px] 2xl:text-xs">
                      {t(
                        'americasNumberOneBoxOfficeV1',
                        `Sound of Freedom was #1 in the box office on July 4 2023, July 10 2023, July 11, and July 20 2023.`,
                      )}
                    </p>
                    <p className="mt-4 text-xs xl:text-[12px] 2xl:text-xs">{tomatoUpdate}</p>
                  </>
                )}
                <p className="mt-6 text-xs uppercase xl:text-[12px] 2xl:text-xs">
                  <Translate t={t} i18nKey="copyright" values={{ currentYear }}>
                    Copyright © {{ currentYear }} by Angel Studios, All Rights Reserved
                  </Translate>
                </p>
              </div>
            </LazyHydrate>
          </div>
          <TicketsFooterSweepstakesRules slug={slug} isDarkMode={isDarkMode} />
        </PaddedContainer>
      </footer>
    </>
  )
}

interface FooterSectionProps {
  label: string
  links: (FooterLink | InvestFooterLink)[]
  footerContentTextClasses: string
}

interface FooterLink {
  label: string
  internalHref?: string
  externalHref?: string
  investLink?: boolean
  manageCookieLink?: boolean
  doNotSellLink?: boolean
}

interface InvestFooterLink extends FooterLink {
  projectSlug: string
  linkContext: string
  externalHref: string
}

const FooterSection: React.FC<FooterSectionProps> = ({ label, links, footerContentTextClasses }) => {
  const track = useSafeTrack()
  const { isDarkMode } = useThemeContext()

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (event.target instanceof HTMLAnchorElement) {
      track('Footer Link Clicked', { href: event.target.href, label: event.target.firstChild?.textContent })
    }
  }

  return (
    <BeforeYouLeaveProvider>
      <BeforeYouLeaveModal />
      <div className={footerSectionSpacingClasses}>
        <h3>{label}</h3>
        <ul className={classNames('w-full flex flex-row flex-wrap xl:flex-col', footerContentTextClasses)}>
          {links.map((link) => {
            if (link.manageCookieLink) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] xl:pr-0 w-1/2 xl:text-[14px] 2xl:text-[16px] xl:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ManageCookieConsentLink className="mt-4 block no-underline">{link.label}</ManageCookieConsentLink>
                </li>
              )
            }

            if (link.doNotSellLink) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] xl:pr-0 w-1/2 xl:text-[14px] 2xl:text-[16px] xl:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ManageDoNotSellLink className="mt-4 block no-underline">{link.label}</ManageDoNotSellLink>
                </li>
              )
            }

            if (link.investLink) {
              const { projectSlug, linkContext, label, externalHref } = link as InvestFooterLink
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] xl:pr-0 w-1/2 xl:text-[14px] 2xl:text-[16px] xl:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={label}
                >
                  <AngelFundingLink
                    className="mt-4"
                    href={externalHref}
                    linkContext={linkContext}
                    onClick={handleLinkClick}
                    projectSlug={projectSlug}
                  >
                    {label}
                  </AngelFundingLink>
                </li>
              )
            }

            if (link.internalHref) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] xl:pr-0 w-1/2 xl:text-[14px] 2xl:text-[16px] xl:w-full mt-4',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <InternalLink href={link.internalHref} onClick={handleLinkClick}>
                    {link.label}
                  </InternalLink>
                </li>
              )
            }

            if (link.externalHref) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] xl:pr-0 w-1/2 xl:text-[14px] 2xl:text-[16px] xl:w-full mt-4',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ExternalLink href={link.externalHref} onClick={handleLinkClick}>
                    {link.label}
                  </ExternalLink>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </BeforeYouLeaveProvider>
  )
}
