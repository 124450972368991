import React from 'react'
import classNames from 'classnames'
import { slugs } from '@/constants'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface TicketsFooterSweepstakesRulesProps {
  slug: string
  isDarkMode: boolean
}

const validSweepstakesSlugs = new Set([slugs.homestead, slugs.braveTheDark, slugs.ruleBreakers])

export const TicketsFooterSweepstakesRules: React.FC<TicketsFooterSweepstakesRulesProps> = ({ slug, isDarkMode }) => {
  const { t } = useTranslate('common')

  if (!validSweepstakesSlugs.has(slug)) return null

  return (
    <div className="space-y-2 px-4 md:px-0">
      <h3>{t('giveawayRules', 'Giveaway Rules')}</h3>
      <p className={classNames('text-[14px] font-light', isDarkMode ? 'text-gray-300' : 'text-gray-700')}>
        {slug === slugs.homestead && (
          <Translate t={t} i18nKey="homesteadSweepstakesGeneralRulesV2">
            * * * LIMIT OF FOURTEEN THOUSAND ONE HUNDRED (14,100) ENTRIES PER PERSON/EMAIL ADDRESS. * * * NO PURCHASE OR
            PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. PURCHASE OR PAYMENT DOES NOT IMPROVE YOUR CHANCE OF
            WINNING. The 300K Instant Homestead Giveaway is open only to legal residents of the 48 Contiguous U.S.
            States (VOID in AK and HI) & DC, 21 or older. Void elsewhere and where prohibited.. Promotion starts
            10/11/24 and ends 12/22/24. To enter, visit https://angel.com/homesteadgiveaway . Sponsor: Angel Studios,
            295 W Center Street, Provo, UT 84601.. Subject to complete Official Rules at{' '}
            <a href="https://bit.ly/HomesteadSweeps" target="_blank" className="underline">
              https://bit.ly/HomesteadSweeps.
            </a>
          </Translate>
        )}
        {(slug === slugs.braveTheDark || slug === slugs.ruleBreakers) && (
          <Translate t={t} i18nKey="btdSweepstakesGeneralRulesFooter">
            * * * LIMIT OF FIVE THOUSAND (5,000) ENTRIES PER PERSON/EMAIL ADDRESS. * * * NO PURCHASE OR PAYMENT OF ANY
            KIND IS NECESSARY TO ENTER OR WIN. PURCHASE OR PAYMENT DOES NOT IMPROVE YOUR CHANCE OF WINNING. The 200K
            Dream Vacation Giveaway is open only to legal residents of the 50 U.S. States & DC, age of majority +. Void
            elsewhere and where prohibited. Promotion ends 3/9/2025. Subject to complete Official Rules at{' '}
            <a href="https://bit.ly/200KDreamGiveaway" target="_blank" className="underline">
              https://bit.ly/200KDreamGiveaway
            </a>
          </Translate>
        )}
      </p>
    </div>
  )
}
